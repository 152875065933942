
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: right;
  justify-content: right;
  background: white;
  width: 100%;
}
.header {
  display: flex;
  flex-direction: row;
  align-content: right;
  justify-content: right;
  width: 80%;
  height: 100%;
  background: white;
}
.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 85vh;
  background-size: cover;
  background: white;
}
.content {
  min-height: 200px;
  width: 80%;
  margin: 30px auto;
  background: white;
  border-radius: 10px;
  padding: 32px;
  box-shadow: 0px 4px 6px #00000029;
}
.border {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0px 2px;
}
.footer {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0px 2px;
}
.header {
  margin-left: 30;
  margin-right: auto;
  font-size: 32px;
  font-weight: bold;
}
.subHeader {
  font-size: 10px;
}
.content p {
  font-size: 15px;
}

.tablist {
  background: white;
}
.footer {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  color: black;
}

.footerLeftLink {
  margin-left: auto;
  margin-right: 120;
  color: black;
}

.footerRightLink {
  margin-left: 120;
  margin-right: auto;
  color: black;
}