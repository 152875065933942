.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
}
.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 85vh;
  background-size: cover;
}
.content {
  min-height: 200px;
  width: 568px;
  margin: 30px auto;
  background: white;
  border-radius: 10px;
  padding: 32px;
  box-shadow: 0px 4px 6px #00000029;
}
.social {
  display: flex;
  align-content: center;
  justify-content: center;
}
.border {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0px 2px;
}
.footer {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0px 2px;
}
.assessmentName {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 32px;
  font-weight: bold;
}
.header {
  margin-left: 30;
  margin-right: auto;
  font-size: 32px;
  font-weight: bold;
}
.subHeader {
  font-size: 10px;
}
.content p {
  font-size: 15px;
}
.form {
  flex-direction: column;
  padding: 15px;
}
.inputs {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.singleInput {
  width: 100%;
  font-size: 10px;
  display: flex;
  flex-direction: column;
}
.singleInput label {
  pointer-events: none;
  position: relative;
  line-height: 14px;
  top: 31px;
  font-size: 14px;
  transition: all 0.15s;
}
.singleInput:focus-within > label,
.singleInput.active > label {
  top: 20px;
  font-size: 10px;
}
.inputs input {
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #c7c7c7;
  padding: 22px 16px 6px;
}
.errored input {
  border: 1px solid #e01218;
  background: #f6edec;
}
.inputs input:hover {
  border: 1px solid #434343;
}
.inputs input:focus {
  border: 1px solid #434343;
  outline: none;
}
.errors {
  padding: 5px 0px;
  color: #e01218;
  font-size: 12px;
}

.inputError {
  padding: 2px 0px;
  color: #e01218;
  font-size: 10px;
}
.loading {
  padding: 10px;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .contentWrapper {
    max-width: 100%;
  }
  .content {
    width: 100%;
    border-radius: 0px;
    padding: 0px;
  }
  .inputs {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  .singleInput {
    width: 100%;
    margin-bottom: 15px;
  }
  .text {
    padding: 15px;
  }
}

.submit {
  border: 1px solid #434343;
  font-size: 16px;
  min-width: 132px;
  /* height: 40px; */
  width: 100%;
  background: lightgray;
  color: white;
  border-radius: 4px;
  padding: 13px 0px;
  box-shadow: 0px 4px 6px #00000029;
  cursor: pointer;
  transition: background 0.25s ease-in-out;
}
.submit:hover {
  background: #3399FF;
}

.footer {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  color: black;
}

.footerLeftLink {
  margin-left: auto;
  margin-right: 120;
  color: black;
}

.footerRightLink {
  margin-left: 120;
  margin-right: auto;
  color: black;
}